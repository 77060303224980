<template>
    <div class="inner-section">
         <card>
           <template v-slot:searchHeaderTitle>
              <h4 class="card-title">{{ $t('elearning_tpm.training_schedule') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                     <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                      <!-- <b-form-input
                        id="circular_memo_no"
                        v-model.lazy="search.circular_memo_no"
                        :placeholder="$t('elearning_iabm.circular_memo_no')"
                      ></b-form-input> -->
                      <b-form-select
                        plain
                        v-model="search.circular_memo_no"
                        :options="circularList"
                        id="circular_memo_no"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="batch_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.batch')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.batch_id"
                          :options="batchList"
                          id="batch_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-button size="sm" variant="primary" @click="searchData">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tpm.training_schedule') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
              <router-link to="training-schedule-form" class="btn btn-add">
                <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
              </router-link>
          </template>
          <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row>
                      <b-col sm="12">
                        <div class="quick-filter-wrapper">
                          <div class="quick-filter-left">
                            <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                              <b-dropdown-form>
                                <div
                                  class="form-group"
                                  v-for="(field, index) in labelData"
                                  :key="index"
                                  >
                                  <b-form-checkbox
                                    :id="'checkbox-' + field.label"
                                    v-model="field.show"
                                    :name="'checkbox-' + field.label"
                                    value=1
                                    unchecked-value=0
                                  >
                                  {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                  </b-form-checkbox>
                                </div>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                          <div class="quick-filter-right">
                            <b-form-group
                              :label="$t('menu.perpage')"
                              label-for="per-page-select"
                            >
                              <b-form-select
                              id="per-page-select"
                              v-model="search.limit"
                              :options="pageOptions"
                              size="sm"
                              ></b-form-select>
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" class="table-responsive">
                        <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                          <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                            <template v-slot:cell(serial)="data">
                              {{ $n(data.item.serial + pagination.slOffset) }}
                            </template>
                            <template v-slot:cell(type_name)="data">
                              <span class="capitalize">{{ data.item.type_name }}</span>
                            </template>
                            <template v-slot:cell(status)="data">
                              <span class="badge badge-primary" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                              <span class="badge badge-secondary" v-else-if="data.item.status == 2">{{$t('globalTrans.forward')}}</span>
                              <span class="badge badge-success" v-else-if="data.item.status == 3">{{$t('globalTrans.approved')}}</span>
                              <span class="badge badge-danger" v-else-if="data.item.status == 4">{{$t('globalTrans.return')}}</span>
                              <span class="badge badge-success" v-else-if="data.item.status == 5">{{$t('globalTrans.published')}}</span>
                            </template>
                            <template v-slot:cell(training_date)="data">
                              <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                            </template>
                            <template v-slot:cell(start_time)="data">
                              <span class="capitalize">{{ data.item.start_time | time12FormateTraining }}</span>
                            </template>
                              <template v-slot:cell(end_time)="data">
                                <span class="capitalize">{{ data.item.end_time | time12FormateTraining }}</span>
                              </template>
                              <template v-slot:cell(action)="data">
                                <!-- <b-button v-if="data.item.status === 1" style="font-size: 12px !important; margin-top:10px" variant="btn_table_action btn_blue" size="sm" @click="finalSave(data.item, 1)" ><i class="fa fa-check" aria-hidden="true"></i></b-button>
                                <b-button v-if="$store.state.Auth.authUser.ssoLogin && data.item.status === 1" style="font-size: 12px !important; margin-top:10px" variant="btn btn-xs btn-success" size="sm" @click="finalSave(data.item, 2)" ><i class="fa fa-forward"></i> {{$t('globalTrans.to_nothi')}}</b-button> -->
                                <!-- <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-4 @click="edit(data.item)">
                                  <i class="fas fa-eye"></i>
                                </a> -->
                                <!-- <router-link class="action-btn edit" variant=" iq-bg-success mr-1" :title="$t('globalTrans.edit')" :to="`training-schedule-form?circular_memo_no=${data.item.circular_memo_no}&batch_id=${data.item.batch_id}`"><i class="ri-ball-pen-fill"></i></router-link> -->
                                <b-button v-if="data.item.status === 1 || data.item.status === 4" :title="$t('globalTrans.forward')" v-b-modal.modal-6 variant="iq-bg-info" class="action-btn btn-info" size="sm" @click="forward(data.item)"><i class="ri-share-forward-line"></i></b-button>
                                <b-button v-if="data.item.status > 1" v-b-modal.modal-form-forward-details variant="iq-bg-info mr-1" size="sm" @click="forwardDetails(data.item)" class="action-btn active" :title="$t('exportTrophyGazette.forward_comment')"><i class="ri-message-2-line"></i></b-button>
                                <b-button v-b-modal.modal-4 variant="iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>
                              </template>
                          </b-table>
                        </slot>
                        <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                      </b-col>
                    </b-row>
              </b-overlay>
          </template>
        </body-card>
        <b-modal id="modal-4" size="lg" hide-footer :title="$t('elearning_tpm.training_schedule') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title>
              {{ $t('elearning_tpm.training_schedule') }}
              <b-button variant="success" @click="pdfExport" class="mr-2 float-right">
                <i class="fas fa-print"></i> {{  $t('globalTrans.export_pdf') }}
              </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="$t('globalTrans.forward')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" hide-footer>
          <Forward :item="itemData"/>
        </b-modal>
        <b-modal id="modal-form-forward-details" size="lg" :title="$t('exportTrophyGazette.forward_comment')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <ForwardDetails :item="itemData"/>
        </b-modal>
    </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingScheduleList, trainingScheduleToggleStatus, nothiRoutinSentList, circularApproveList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Forward from './Forward'
import ForwardDetails from './ForwardComments.vue'
import Details from './Details'
import Store from '@/store'
import ExportPdf from './export_pdf_list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details, Forward, ForwardDetails
    },
    data () {
        return {
          circularLoading: false,
          itemData: [],
          sortBy: '',
          sortDirection: '',
          sortDesc: '',
          search: {
            circular_memo_no: 0,
            org_id: [],
            batch_id: 0,
            training_type_id: 0,
            training_title_id: 0,
            fiscal_year_id: [],
            limit: 20
          },
          trainingTitleList: [],
          batchList: [],
          circularList: [],
          allData: [],
          labelData: [
            { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
            { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
            { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
            { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
            { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
            { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
            { labels: 'elearning_config.batch', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '35%' } },
            { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
            { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 9, thStyle: { width: '15%' } }
          ]
        }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'serial' },
              { key: 'fiscal_year_bn' },
              { key: 'circular_memo_no' },
              { key: 'org_bn' },
              { key: 'training_type_bn' },
              { key: 'training_title_bn' },
              { key: 'batch_bn' },
              { key: 'status' },
              { key: 'action' }
            ]
        } else {
            keys = [
              { key: 'serial' },
              { key: 'fiscal_year' },
              { key: 'circular_memo_no' },
              { key: 'org' },
              { key: 'training_type' },
              { key: 'training_title' },
              { key: 'batch' },
              { key: 'status' },
              { key: 'action' }
            ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            fiscal_year_id: this.$store.state.currentFiscalYearId
        })
        this.loadData()
        // this.getCircularList()
    },
    watch: {
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.org_id': function (newValue) {
        this.batchList = this.getBatchList(newValue)
      }
    },
    methods: {
      forward (item) {
        this.itemData = item
      },
      forwardDetails (item) {
        this.itemData = item
      },
      getBatchList (orgId) {
        const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
        if (orgId) {
          return batchList.filter(item => item.org_id === orgId)
        }
        return batchList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularApproveList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
            const listObject = result.data
            const tmpList = listObject.map((obj, index) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.circular_memo_no, text: obj.circular_memo_no }
              } else {
                return { value: obj.circular_memo_no, text: obj.circular_memo_no }
              }
            })
            this.circularList = tmpList
          }
        this.circularLoading = false
      },
      finalSave (item, type) {
        if (type === 1) {
          this.toggleStatusChange(trainingElearningServiceBaseUrl, trainingScheduleToggleStatus, item)
        } else {
          this.toggleStatusCustom2(trainingElearningServiceBaseUrl, nothiRoutinSentList, item)
        }
      },
      toggleStatusChange (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.approveStatusChange'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.postData(baseUrl, uri, item).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getTrainingTitleList (typeId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (typeId) {
          return trainingTitleList.filter(item => item.training_type_id === typeId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, trainingScheduleToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data, this.search.limit)
                  this.allData = this.getCustomDataList(response.data.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          return Object.assign({}, { serial: index }, item, orgData, batchData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      pdfExportList () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Traning Schedule List' : 'প্রশিক্ষণের সময়সূচী তালিকা'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.allData)
      }
    }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
